window.HolidayForm = class HolidayForm {
    constructor() {
        this.date_start = $('#holiday_begin_date');
        this.date_end = $('#holiday_end_date');
        this.holiday_nb_day_selected = $('#holiday_nb_day_selected');
        this.number_days_left = $('#number_days_left');
        this.holiday_half_start_day = $('#holiday_half_start_day');
        this.holiday_half_end_day = $('#holiday_half_end_day');
        this.holiday_half_day_type = $('#holiday_half_day_type');
    }

    init_listener() {
        this.date_start.change(() => {
            this.toggle_half_end();
            this.toggle_half_day_type();
        });
        this.date_end.change(() => {
            this.toggle_half_end();
            this.toggle_half_day_type();
        });
        this.holiday_half_start_day.change(() => {
            this.toggle_half_day_type();
        });
        this.holiday_nb_day_selected.change(() => {
            this.calc_total_left();
        });

        return this;
    }

    init() {
        new AddFields(this.init_field.bind(this));
        new RemoveFields(this.calc_total_left.bind(this));
        this.init_field();
        this.toggle_half_end();
        this.toggle_half_day_type();
        this.calc_total_left();

        return this;
    }

    calc_total_left() {
        let tot = 0;
        $('.selected_days').each((index, element) => {
            let these = $(element);
            if (these.is(":hidden")) {
                return;
            }

            let val = these.val();
            tot += val ? parseFloat(val) : 0;
        });

        let real = this.holiday_nb_day_selected.val() - tot;
        this.number_days_left.text(real || real === 0 ? real : '-');
    }

    toggle_half_day_type() {
        let parent = this.holiday_half_day_type.closest('div.field');
        parent.hide();
        if (this.date_start.val() === this.date_end.val() && this.holiday_half_start_day.prop("checked") && this.date_start.val()) {
            parent.show();
        }
    }

    toggle_cause_field(_index, target ) {
        let element = $(target);
        let cause = element.closest('div.inline-fields').find('div.cause-field');
        cause.hide();
        if (element.val() === 'unusual') {
            cause.show();
        }
    }

    toggle_half_end() {
        let parent = this.holiday_half_end_day.closest('div.field');
        parent.show();
        if (this.date_start.val() === this.date_end.val() && this.date_start.val()) {
            parent.hide();
            this.holiday_half_end_day.prop('checked', false);
        }
    }

    init_field() {
        $('.selected_days').keyup(this.calc_total_left.bind(this)).change(this.calc_total_left.bind(this));
        $('.holiday_type').change((event) => {
          this.toggle_cause_field(null, event.target);
        }).each(this.toggle_cause_field.bind(this));
    }

    is_weekend(date) {
        return date.getDay() === 0 || date.getDay() === 6;
    }
}