window.toggleRows = function($this, objectClass) {
  let i = $this.find('i')
  rowId = objectClass == 'client' ? $this.data('id') : $this.data('parent') + $this.data('id')

  if (i.hasClass('right')) {
    let targets = $(`tr[data-parent='${rowId}']`)
    targets.removeClass('hidden');
  } else {
    let targets = $(`tr[data-${objectClass}='${rowId}']`)
    targets.addClass('hidden');
    targets.find('i').addClass('right');
    targets.find('i').removeClass('down');
  }
  i.toggleClass('right');
  i.toggleClass('down');
}
