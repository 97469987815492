window.resource_form = window.resource_form || {};

resource_form.resource_change = function(form) {
  $('.ui.dropdown.resource').dropdown('setting', 'onChange', ()=>{
    $('#reload').val('true');
    Rails.fire(form[0], 'submit');
  });
};

resource_form.init_form = function(form){
  resource_form.resource_change(form);
};

$(document).on('turbolinks:load', function() {
  resource_form.init_form($('form'));
});
