import {Controller} from "stimulus"

export default class planning extends Controller {
    static get targets() {
        return [
            "cell", "priority", "projectTime",
            "saveButton"
        ]
    }

    connect() {

    }

    //--------------------------------- EVENT: ---------------------------------

    onChangePriority() {
        const cell = event.currentTarget;
        const sibling = cell.parentNode.querySelector("input.priority");

        if (cell.classList.contains('blue')) {
            cell.classList.remove('blue');
            sibling.value = 0;
        } else {
            cell.classList.add('blue');
            sibling.value = 1;
        }
    }

    onChangeAffectation() {
        const cell = event.currentTarget;
        const cells = this.cellTargets
        const projects_cells = this.projectTimeTargets
        const cell_projects = cells.filter((cell_filtered) => {
            return cell_filtered.dataset.projectId === cell.dataset.projectId &&
                cell_filtered.dataset.resourceId === cell.dataset.resourceId
        })
        const tr = cell.parentNode.parentNode
        const save_button = this.saveButtonTarget

        const project_time = projects_cells.filter(cell_filtered =>
            cell_filtered.dataset.projectId === cell.dataset.projectId &&
                cell_filtered.dataset.resourceId === cell.dataset.resourceId
        )[0].dataset.time

        save_button.disabled = false
        tr.classList.remove('invalid')

        if (project_time === 'NA') {
            return;
        }

        if (project_time < cell_projects.map(cell => cell.value ? cell.value : 0).reduce((a, b) => parseFloat(a) + parseFloat(b))) {
            tr.classList.add('invalid')
            save_button.disabled = true
        }
    }
}