window.initSlotForm = function() {
  initDropdown();
  init_reload_dropdown(() => {
      $('#task').dropdown('clear');
      $('#business_task').dropdown('clear');
  });
}

function updateTaskName(selector){
  text = selector.text()
  text = text.replace('[Nouveau] ', '')
  text = text.replace('[ASANA] ', '')
  text = text.replace('[DISPATCHER] ', '')
  $('#slot_task_name').val(text)
}

window.initTaskDropDown = function(url) {
  $('#task').dropdown({
    allowAdditions: true,
    saveRemoteData: false,
    minCharacters: 2,
    apiSettings: {
      cache: false,
      url: url + '?search={query}&billable_id={billable_id}&billable_type={billable_type}',
      beforeSend: function(settings){
        settings.urlData = {
          query: $('.task input.search').val(),
          billable_id: $('#slot_billable_id').val(),
          billable_type: $('#slot_billable_type').val()
        }
        return settings
      }
    },
    onChange: function(){
      updateTaskName($('.dropdown.task .text'))
    }
  });
}

window.initBusinessTaskDropDown = function(url) {
  $('#select-business-task').dropdown({
      fullTextSearch: "exact",
      saveRemoteData: false,
      minCharacters: 2,
      apiSettings: {
          cache: false,
          url:  '/business_tasks/search?search={query}'
      },
      onChange: function(){
        updateTaskName($('.dropdown.task .text'))
      }
  });
}

window.initBillableDropdown = function() {
  let billables = []
  $('#slot_billable_id').dropdown({
      fullTextSearch: "exact",
      saveRemoteData: false,
      minCharacters: 2,
      apiSettings: {
          cache: false,
          url: '/slots/search_billables?search={query}',
          onResponse: function(response){
            billables = response.results
            return response
          },
      },
      onChange: function(value){
        let billable = billables.find((e)=> e.value == value)
        $('#slot_billable_type').val(billable.class_name)
      }
  });
}
