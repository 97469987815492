window.save_sort_documents = function(items, url) {
    let json_data = [];
    ([...items]).map((item, index) => {
        json_data.push({
            id: item.dataset.documentId,
            order: index,
            parent_id: item.parentElement.dataset.parentId
        });
    });

    console.log(json_data.values())

    $.ajax({
        type: "POST",
        url: url,
        data: { arbo: JSON.stringify(json_data) },
        dataType: "json",
        success: function(response) {},
    });
}

window.displayRichText = function() {
  if ($('form #document_dir').prop('checked')) {
    $('#document_content').hide()
    $('#trix-toolbar-1').hide()
  }else{
    $('#document_content').show()
    $('#trix-toolbar-1').show()
  }
}

window.initArbo = function(){
  let elements = document.getElementsByClassName('sortable-list');
  ([...elements]).forEach((el) => {
    Sortable.create(el, {
      group: 'sortable',
      onChange: function(evt) {
        save_sort_documents(
          document.querySelectorAll('div.internal-document div.item'),
          $('.sortable-list').data('url')
        );
      }
    });
  });
}

$(document).on('turbolinks:load', function() {
  $('form #document_dir').change(() => {
    displayRichText()
  })
  if ($('.documentation-documents')) {
    initArbo()
  }
  displayRichText()
})
