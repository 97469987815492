import {Controller} from "stimulus"

export default class resourcesassignment extends Controller {
    static get targets() {
        return [
            "cell", "holidayCell", "openDay",
            "projectTotal", "devTotal", "bizTotal", "freelanceTotal",
            "resourceTotal",
            "totalDevTotal", "totalBizTotal", "totalFreelanceTotal", "totalTotal",
            "holidayDevTotal", "holidayBizTotal", "holidayFreelanceTotal", "holidayTotal",
            "workedDayDevTotal", "workedDayBizTotal", "workedDayFreelanceTotal", "totalWorkedDayTotal",
            "workedDayTotal",
            "saveButton"
        ]
    }

    connect() {
        this.holidayCellTargets.forEach((cell) => {
            this.total_holiday_resource_type(cell)
            this.holidayTotalTarget.textContent = this.sumCells(this.holidayCellTargets)
            this.total_work_day_resource(cell)
            this.total_work_day_by_resource_type(cell)
        })

        this.total_work_days()

        const cells = this.cellTargets
        const cell_dev = cells.filter(cell_filtered => cell_filtered.dataset.resourceType.includes("dev"))[0]
        const cell_biz = cells.filter(cell_filtered => cell_filtered.dataset.resourceType.includes("biz"))[0]
        const cell_freelance = cells.filter(cell_filtered => cell_filtered.dataset.resourceType.includes("freelance"))[0]

        if (cell_dev !== undefined) {
            this.init_sheet_resource_type(cells, cell_dev)
        }
        if (cell_biz !== undefined) {
            this.init_sheet_resource_type(cells, cell_biz)
        }
        if (cell_freelance !== undefined) {
            this.init_sheet_resource_type(cells, cell_freelance)
        }
        cells.filter(cell_filtered => cell_dev.dataset.project === cell_filtered.dataset.project).forEach((cell_filtered) => {
            this.total_resource(cell_filtered)
            this.total_resource_type(cell_filtered)
        })

        this.total_total()
    }

    //--------------------------------- EVENT: ---------------------------------

    onChangeHolidayCell() {
        const cell = event.currentTarget

        this.total_holiday_resource_type(cell)
        this.holidayTotalTarget.textContent = this.sumCells(this.holidayCellTargets)
        this.total_work_day_resource(cell)
        this.total_work_day_by_resource_type(cell)
        this.is_possible(cell)
        this.total_work_days()
    }

    onChangeCell() {
        const cell = event.currentTarget

        this.total_project_resource_type(cell)
        this.total_project(cell)
        this.total_resource(cell)
        this.total_resource_type(cell)
        this.is_possible(cell)
        this.total_total()
    }

    //----------------------- Main: ---------------------------------

    total_project_resource_type(cell) {
        let resourceType = cell.dataset.resourceType
        let projectId = cell.dataset.project

        if (resourceType.includes("dev")) {
            this.total_project_by_resource_type(this.devTotalTargets, projectId, "dev")
        } else if (resourceType.includes("biz")) {
            this.total_project_by_resource_type(this.bizTotalTargets, projectId, "biz")
        } else if (resourceType.includes("freelance")) {
            this.total_project_by_resource_type(this.freelanceTotalTargets, projectId, "freelance")
        }
    }

    total_resource(cell) {
        const resource = cell.dataset.resource
        const cells = this.cellTargets.filter(cell => cell.dataset.resource === resource)

        this.resourceTotalTargets.forEach((element) => {
            if (element.dataset.resource === resource) {
                element.textContent = this.sumCells(cells)
            }
        })
    }

    total_resource_type(cell) {
        let resourceType = cell.dataset.resourceType
        let outputTarget = null
        let inputTargets = []

        if (resourceType.includes("dev")) {
            outputTarget = this.totalDevTotalTarget;
            inputTargets = this.devTotalTargets;
        } else if (resourceType.includes("biz")) {
            outputTarget = this.totalBizTotalTarget;
            inputTargets = this.bizTotalTargets;
        } else if (resourceType.includes("freelance")) {
            outputTarget = this.totalFreelanceTotalTarget;
            inputTargets = this.freelanceTotalTargets;
        } else {
            return;
        }

        outputTarget.textContent = this.sumCells(inputTargets)
    }

    total_project(cell) {
        const project = cell.dataset.project
        const cells = this.cellTargets.filter(cell => cell.dataset.project === project)

        this.projectTotalTargets.forEach((element) => {
            if (element.dataset.project === project) {
                element.textContent = this.sumCells(cells)
            }
        })
    }

    total_total() {
        this.totalTotalTarget.textContent = this.sumCells(this.projectTotalTargets)
    }

    total_project_by_resource_type(outputTarget, project, resourceType) {
        const cells = this.cellTargets.filter(cell => cell.dataset.project === project && cell.dataset.resourceType.includes(resourceType))

        outputTarget.forEach((element) => {
            if (element.dataset.project === project) {
                element.textContent = this.sumCells(cells)
            }
        })
    }

    is_possible(cell) {
        const resource = cell.dataset.resource
        const available_day = this.workedDayTotalTargets.filter(cell => cell.dataset.resource === resource)[0]
        const resource_total = this.resourceTotalTargets.filter(cell => cell.dataset.resource === resource)[0]
        const save_button = this.saveButtonTarget

        resource_total.classList.remove('error')
        save_button.disabled = false
        if (parseFloat(available_day.textContent) < parseFloat(resource_total.textContent) ) {
            resource_total.classList.add('error')

        }

        save_button.disabled = this.resourceTotalTargets.map(resource => resource.classList.contains('error')).includes(true)
    }

    init_sheet_resource_type(cells, target) {
        cells.filter(cell_filtered => target.dataset.resource === cell_filtered.dataset.resource).forEach((cell_filtered) => {
            this.total_project_resource_type(cell_filtered)
            this.total_project(cell_filtered)
        })
    }


    //--------------------------------- Holiday : ---------------------------------

    total_work_day_resource(cell) {
        const resource = cell.dataset.resource
        let output_target = this.workedDayTotalTargets.filter(cell => cell.dataset.resource === resource)[0]
        const open_day = parseFloat(this.openDayTarget.textContent)
        const cell_value = cell.value ? parseFloat(cell.value) : 0

        output_target.textContent =  open_day - cell_value
    }

    total_work_day_by_resource_type(cell) {
        let resource_type = cell.dataset.resourceType

        let output_target;
        if (resource_type.includes("dev")) {
            output_target = this.workedDayDevTotalTarget
        } else if (resource_type.includes("biz")) {
            output_target = this.workedDayBizTotalTarget
        } else if (resource_type.includes("freelance")) {
            output_target = this.workedDayFreelanceTotalTarget
        } else {
            return;
        }

        const input_cells = this.get_by_resource_type(this.workedDayTotalTargets, resource_type)
        output_target.textContent = this.sumCells(input_cells)
    }

    total_holiday_by_resource_type(outputTarget, resourceType) {
        const cells = this.holidayCellTargets.filter(cell => cell.dataset.resourceType.includes(resourceType))
        outputTarget.textContent = this.sumCells(cells)
    }

    total_holiday_resource_type(cell) {
        let resourceType = cell.dataset.resourceType

        if (resourceType.includes("dev")) {
            this.total_holiday_by_resource_type(this.holidayDevTotalTarget, "dev")
        } else if (resourceType.includes("biz")) {
            this.total_holiday_by_resource_type(this.holidayBizTotalTarget, "biz")
        } else if (resourceType.includes("freelance")) {
            this.total_holiday_by_resource_type(this.holidayFreelanceTotalTarget, "freelance")
        }
    }

    total_work_days() {
        this.totalWorkedDayTotalTarget.textContent = this.sumCells(this.workedDayTotalTargets)
    }

    get_by_resource_type(cells, resource_type) {
        return cells.filter(cell => cell.dataset.resourceType.includes(resource_type))
    }
    sumCells(cells) {
        return cells.map(cell => cell.value ? cell.value : (cell.textContent ? cell.textContent : 0) )
            .reduce((a, b) => parseFloat(a) + parseFloat(b))
    }
}
