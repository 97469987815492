import "./activity_time";
import "./affectation"
import "./clickable";
import "./document"
import "./documentation";
import "./init_semantic";
import "./journal_entries"
import "./holidays/holiday_form";
import "./nested_forms/add_fields";
import "./nested_forms/remove_fields";
import "./real_data"
import "./resource";
import "./sick_day";
import "./slot_affectation";
import "./slots";
import "./users"
