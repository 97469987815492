window.slot_affectation_form = window.slot_affectation_form || {};

slot_affectation_form.resource_change = function(form) {
  $('.ui.dropdown.resource').dropdown('setting', 'onChange', ()=>{
    var resource = "";
    for (var i = 0; i < $('.resource .item').length; ++i) {
      if ($('.resource .item')[i].innerHTML == $('.resource .text')[0].innerHTML){
        resource = $('.resource .item')[i].dataset.value;
      }
    }
    Rails.ajax({
      url: "/slot_affectations/new?resource="+ resource,
      type: "get"
    });
  });
};


slot_affectation_form.init_form = function(form){
  slot_affectation_form.resource_change(form);
};

$(document).on('turbolinks:load', function() {
  slot_affectation_form.init_form($('form'));
});
