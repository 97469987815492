window.initForm = function(){
  initDropdown();
  initVisual();
  initStartDate();
  initEndDate();
  initStartHalfDay();
}

function initVisual(){
  $('.half_day_type').hide();
  if($('#sick_day_half_start_day').parent().checkbox('is checked') &&
     $('#sick_day_end_date').val() == $('#sick_day_begin_date').val()){
    $('.half_day_type').show();
  }
  if($('#sick_day_begin_date').val() == $('#sick_day_end_date').val()){
    $('.half_end_day').hide();
    changeStartHalfDayLabel('day', false);
  }else {
    changeStartHalfDayLabel('period', false);
  }
}

function initStartDate(){
  datesEvent($('#sick_day_begin_date'), $('#sick_day_end_date'));
}

function initEndDate(){
  datesEvent($('#sick_day_end_date'), $('#sick_day_begin_date'));
}

function datesEvent(field, other_date_field){
  field.change((event) => {
    if(event.target.value != other_date_field.val()) {
      $('.half_end_day').show();
      $('.half_day_type').hide();
      changeStartHalfDayLabel('period');
    }else{
      changeStartHalfDayLabel('day');
      $('.half_end_day').hide();
      $('#sick_day_half_end_day').parent().checkbox('uncheck');
    }
  });
}

function initStartHalfDay(){
  $('#sick_day_half_start_day').change((event) =>{
    if(event.target.checked && $('#sick_day_end_date').val() == $('#sick_day_begin_date').val()) {
      $('.half_day_type').show();
    }else{
      $('.half_day_type').hide();
    }
  });
}

function changeStartHalfDayLabel(current_state, reset_status = true){
  if (current_state == 'period') {
    $('#half_start_day_label').show();
    $('#half_day_label').hide();
    if (reset_status) { $('#sick_day_half_start_day').parent().checkbox('uncheck'); }
  }else {
    $('#half_start_day_label').hide();
    $('#half_day_label').show();
    if (reset_status) { $('#sick_day_half_start_day').parent().checkbox('uncheck'); }
  }
}
