window.RemoveFields = class RemoveFields {
    constructor(callback = () => {}) {
        this.iterateLinks()
        this.callback = callback
    }

    iterateLinks() {
        document.addEventListener('click', e => {
            let target = undefined;

            let closest = e.target.closest('.remove_fields')
            if (closest) {
                target = closest
            }

            if (e.target.classList.contains('remove_fields')) {
                target = e.target
            }

            if (!target) {
                return
            }

            this.handleClick(target, e)
        });
    }

    handleClick(link, e) {
        if (!link || !e) return
        e.preventDefault()
        let fieldParent = link.closest('.nested-fields')
        let deleteField = fieldParent
            ? fieldParent.querySelector('input[type="hidden"]')
            : null
        if (deleteField) {
            if (fieldParent.classList.contains('self-added')) {
                fieldParent.remove();
                return;
            }
            deleteField.value = 1
            fieldParent.style.display = 'none'
        }

        this.callback()
    }
}

// window.addEventListener('turbolinks:load', () => new RemoveFields())