$(document).on('turbolinks:load', function() {
  $('#archived_toggle').change(() => {
    Turbolinks.visit($('#filter-archived').data('url'));
  });
})

window.initFormToggle = function(){
  var checkbox_elem = $("#user_freelance");

  checkbox_elem.change(function () {
      toggle_freelance($(this));
  });

  toggle_freelance(checkbox_elem);
}

function toggle_freelance(checkbox_elem) {
    let that = checkbox_elem.parent();
    let attribute_no_freelance = $(".no_freelance");

    attribute_no_freelance.hide();
    if (!that.checkbox('is checked')) {
        attribute_no_freelance.show();
    }
}
