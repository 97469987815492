$(document).on('turbolinks:load', function() {
  $('tr.clickable').on("click", function(e) {
    let target = $(e.target)
    if ((target.is('td') && target.hasClass('actions')) || target.parents('td.actions').length) {
      return;
    }

    if ($(this).data('remote')) {
      $.ajax({
        headers: { "Accept":"text/javascript, application/javascript, application/ecmascript, application/x-ecmascript"},
        url: $(this).data('url'),
        type: "GET"
      });

      return;
    }
    Turbolinks.visit($(this).data('url'));
  });
});
