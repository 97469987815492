window.bu_time_form = window.bu_time_form || {};

bu_time_form.init_form = function(form){
  $('.project_dev_time, .project_gpt_time, .project_infra_time').on("click keydown",()=>{ setTimeout(change_time); });
};

function get_model() {
  return ($('body').hasClass('affectations') ? 'affectation' : 'provision');
};

function change_time(){
  function get_value(name) { return parseFloat(document.getElementById(get_model() + '_' + name).value || 0); };
  document.getElementById('time').textContent = get_value('dev_time') + get_value('gpt_time') + get_value('infra_time');
};

$(document).on('turbolinks:load', function() {
  bu_time_form.init_form($('form'));
});
