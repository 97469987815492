window.documentation_func = window.documentation_func || {};

$(document).on('turbolinks:load', function() {
  $("#copy").click(copySshPublicKey);
  $("li i.caret.icon").click(toggleMenu);
  $('#copy_url').click(documentation_func.copyUrl)
  if ($('.documentation-home, .documentation-clients, .documentation-projects')) {
    documentation_func.activeCurrentMenu();
  }
});

documentation_func.copyUrl = function () {
  event.preventDefault();
  let tmp = document.createElement("textarea");
  tmp.value = $(this).find('input').val();
  tmp.style.height = "0";
  tmp.style.overflow = "hidden";
  tmp.style.position = "fixed";
  document.body.appendChild(tmp);
  tmp.focus();
  tmp.select();
  document.execCommand("copy");
  document.body.removeChild(tmp);
}

function copySshPublicKey() {
  let button = document.querySelector("#copy");
  let copyText = document.querySelector("#ssh_public_key");
  copyText.select();
  document.execCommand("copy");
  button.textContent = "Copié !";
  button.classList.remove('positive');

  setTimeout(function () {
    button.textContent = "Copier";
    button.classList.add('positive');
  }, 1000)
}

function toggleMenu({target}) {
  if (target.classList.contains('right')) {
    //open
    $(target).removeClass('right').addClass('down');
    $(target).parent().find('> ul').removeClass('hidden');
    return;
  }
  //close
  target.classList.remove('down');
  target.classList.add('right');
  $(target).parent().find('ul').each(function () {
    $(this).addClass('hidden');
    $(this).parent().find('.caret.icon').removeClass('down').addClass('right');
  });
}

documentation_func.activeCurrentMenu = function () {
  let currentMenu = document.querySelector(`a[href='${window.location.pathname}']`);
  if (!currentMenu) {
    return
  }
  $('li a.active').removeClass('active');
  currentMenu.classList.add('active');
  openParentMenu($(currentMenu).parents('ul'));
}

function openParentMenu(target, callX = 0) {
  if (!target.length || callX > 10) {
    return;
  }
  target.removeClass('hidden');
  target.parent().find('> i.caret.icon').removeClass('right').addClass('down');
  openParentMenu(target.parents('ul'), callX+1);
}
