$(document).on('turbolinks:load', function() {
  $('.ui.accordion.specific.client').accordion()
  $('#freelance_slider').change(() => {
    let url = $('.slider.checkbox').data('url')
    if ($('.slider.checkbox').data('freelance') != true) {
      url += '?freelance=true'
    }
    console.log(url)
    Turbolinks.visit(url)
  })
})
