import Rails from '@rails/ujs';

window.initDropdown = () => {
    $('.ui.dropdown:not(.initialize):not(.clearable):not(.specific)').dropdown();
    $('.ui.dropdown.clearable:not(.specific)').dropdown({'placeholder': false, "clearable": true});
    $('.ui.dropdown').addClass('initialize');
    $('div[data-content]').popup();
}

window.initToggleCheckbox = () => {
    $('.ui.checkbox').checkbox();
}
window.initAccordion = () => {
    $('.ui.accordion:not(.initialize):not(.specific)').accordion();
    $('.ui.accordion').addClass('initialize');
}

$(document).on('ready load turbolinks:load', function () {
    setTimeout(() => {
        $('.ui.dropdown').removeClass('initialize');
    }, 1);
    $('.message .close').on('click', function () {
        $(this).closest('.message').transition('fade');
    });
    initDropdown();
    initAccordion();
    initToggleCheckbox();
    after_init();
    init_reload_dropdown(() => {
    })
});

window.init_reload_dropdown = (callback) => {
    $('.reload-dropdown').dropdown('setting', 'onChange', () => {
        let reload = $('#reload');
        reload.val('true');
        let form = reload.closest('form');
        callback();
        setTimeout(() => {
            Rails.fire(form[0], 'submit');
        }, 10);
    });
}

window.reload = () => {
    let reload = $('#reload');
    reload.val('true');
    let form = reload.closest('form');
    setTimeout(() => {
        Rails.fire(form[0], 'submit');
    }, 10);
}
